import { render, staticRenderFns } from "./FilteredSermonList.vue?vue&type=template&id=2c63b171&"
import script from "./FilteredSermonList.vue?vue&type=script&lang=ts&"
export * from "./FilteredSermonList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports