
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import SiteLayoutSwitcher, {
  LayoutSwitcherProps,
} from '~/components/site/LayoutSwitcher.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'

export type CountType = 'sermon' | 'series' | 'article'

export const ListHeaderWrapperProps = {
  showCount: {
    type: Boolean,
    default: true,
  },
  topBorder: {
    type: Boolean,
    default: true,
  },
  disableSort: {
    type: Boolean,
  },
  disableSearch: {
    type: Boolean,
  },
  customCountText: {
    type: String as PropType<TranslateResult>,
    default: undefined,
    validator(value: TranslateResult) {
      if (!value) return true
      return value.toString().includes('{n}')
    },
  },
  count: {
    type: Number,
    default: undefined,
  },
  disableFilters: {
    type: Boolean,
  },
  customQ: {
    type: String,
    default: 'alt-layout',
  },
  displaySwitcher: {
    type: Boolean,
    default: true,
  },
}

export default Vue.extend({
  name: 'ListHeaderWrapper',
  components: {
    LoadingElement,
    SiteLayoutSwitcher,
    HorizontalRule,
  },
  props: {
    ...ListHeaderWrapperProps,
    ...LayoutSwitcherProps,
    countType: {
      type: String as PropType<CountType>,
      required: true,
    },
  },
  computed: {
    countDisplay(): TranslateResult {
      if (this.count === undefined) return ''
      const seriesDisplay =
        this.count === 10000 ? '10k+' : this.count.toLocaleString()
      if (this.customCountText) {
        return this.$tc(this.customCountText.toString(), this.count, {
          n: seriesDisplay,
        })
      } else {
        let tString = ''
        switch (this.countType) {
          case 'article':
            tString = '{n} Article | {n} Articles'
            break
          case 'sermon':
            tString = '{n} Sermon | {n} Sermons'
            break
          default:
            tString = '{n} Series | {n} Series'
        }
        return this.$tc(tString, this.count, {
          n: seriesDisplay,
        })
      }
    },
    loadingClasses(): string {
      return this.customCountText ? 'w-32' : 'w-56'
    },
  },
})
